.faq__summaryShell {
    
    

}

.faq__summarySection {
    
    margin-top: 1.6rem;

}

.faq__section {
    margin-bottom: 5rem;
}

.faq__summarySectionName {
    margin-bottom: .9em;
    font-size: 1.1em;
}

.faq__sectionTitle {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.faq__summaryEntry {
    margin-bottom: .5rem;
}

.faq__summaryLink:not(:hover) {
    background: none;
}

.faq__entryQuestion {
    font-size: 1.3em;
    line-height: 1.3em;
    font-weight: bold;
    margin-bottom: 1.5rem;
}   

.faq__entry {
    margin-bottom: 4rem;
}