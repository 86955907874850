


.featureImg {
    display: block;
    max-width: 100%;
    height: auto;
}

.articleBody {
    h3 {
        margin-top:3rem;
        font-size: 1.65rem;
    }
    img {
        display: block;
        max-width: 100%;
        height: auto;
    }
}

