.blog {
    background-color:#f5f5f7;
}

.pagination {
    display:flex;
    justify-content:center;
    align-items:center;
    margin: 4rem 0;
}

.pressList {
    li {
        margin-bottom: 1rem;
    }
}
